import React from "react";
import Layout from "@/components/layout/Layout";
import Button from "@/components/button/Button";
import { list } from "./index.module.scss";

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

export default function Home() {
  return (
    <Layout>
      <main>
        <div
          className="min-h-[500px] flex flex-row bg-gradient-to-tr from-ibb to-ibb-accent px-4 md:px-8 lg:px-16 py-16 md:py-32 text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 gap-x-32 max-w-[1630px] mx-auto">
            <div className="flex flex-col space-y-8 md:space-y-16">
              <h1 className="text-5xl md:text-6xl font-bold">
                ibb e-learning
              </h1>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore magna aliquyam erat, sed diam voluptua.
                </p>
                <p>
                  Sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                  vero eos et accusam et justo duo.
                </p>
              </div>
              <div className="flex flex-row items-center space-x-8">
                <Link to="/kurse">
                  <Button type="secondary">
                    Kurse entdecken
                  </Button>
                </Link>
                <Link to="/registrieren">
                  <Button type="primary">
                    Registrieren
                  </Button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <StaticImage src="./../static/images/player.example.jpg" alt="" placeholder="tracedSVG" />
            </div>
          </div>
        </div>

        <div
          className="min-h-[500px] flex flex-row bg-white text-slate-700 px-4 md:px-8 lg:px-16 py-16 md:py-32 text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 gap-x-32 max-w-[1630px] mx-auto">
            <div className="flex flex-col items-center justify-center">
              <StaticImage src="./../static/images/creo.example.png" alt="" placeholder="tracedSVG" />
            </div>
            <div className="flex flex-col space-y-8 md:space-y-16">
              <h2 className="text-5xl md:text-6xl font-bold text-ibb inline-flex flex-col">
                <span className="text-3xl">Grundschulung</span>
                <span>creo parametric</span>
              </h2>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore magna aliquyam erat, sed diam voluptua.
                </p>
              </div>
              <div className="flex flex-row items-center space-x-8">
                <Link to="/kurse/creo-parametric-grundschulung">
                  <Button type="primary">
                    Kursvorschau
                  </Button>
                </Link>
                <Link to="/kurse/creo-parametric-grundschulung/buchen">
                  <Button type="secondary">
                    Kurs buchen
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="min-h-[500px] flex flex-row bg-slate-100 text-slate-700 px-4 md:px-8 lg:px-16 py-16 md:py-32 text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 gap-x-32 max-w-[1630px] mx-auto">
            <div className="order-2 md:order-1 flex flex-col justify-center space-y-8 md:space-y-16">
              <h2 className="text-5xl md:text-6xl font-bold text-ibb inline-flex flex-col">
                <span className="text-3xl">creo parametric</span>
                <span>Konstruktion</span>
              </h2>
              <div>
                <p>
                  Sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                  vero eos et accusam et justo duo. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut.
                </p>
              </div>
              <div className="flex flex-row items-center space-x-8">
                <Link to="/kurse/creo-parametric-konstruktion">
                  <Button type="primary">
                    Kursvorschau
                  </Button>
                </Link>
                <Link to="/kurse/creo-parametric-konstruktion/buchen">
                  <Button type="secondary">
                    Kurs buchen
                  </Button>
                </Link>
              </div>
            </div>
            <div className="order-1 md:order-2 flex flex-col items-center justify-center">
              <StaticImage src="./../static/images/cad.example.png" alt="" placeholder="tracedSVG" />
            </div>
          </div>
        </div>

        <div
          className="min-h-[500px] flex flex-row bg-white text-slate-700 px-4 md:px-8 lg:px-16 py-20 md:py-24 text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 gap-x-32 max-w-[1630px] mx-auto">
            <div className="relative">
              <div
                className="relative md:absolute md:z-[0] md:top-[-12rem] md:w-[130%] md:h-[220%] flex flex-col items-center justify-center">
                <StaticImage src="./../static/images/fem.example.png" alt="" placeholder="tracedSVG" />
              </div>
            </div>
            <div className="relative flex flex-col space-y-8 md:space-y-16 z-1">
              <h2 className="text-5xl md:text-6xl font-bold text-ibb inline-flex flex-col">
                <span className="text-3xl">Intensivkurs</span>
                <span>FEM-Berechnung</span>
              </h2>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore magna aliquyam erat, sed diam voluptua.
                </p>
              </div>
              <div className="flex flex-row items-center space-x-8">
                <Link to="/kurse/fem-berechnung-intensivkurs">
                  <Button type="primary">
                    Kursvorschau
                  </Button>
                </Link>
                <Link to="/kurse/fem-berechnung-intensivkurs/buchen">
                  <Button type="secondary">
                    Kurs buchen
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="min-h-[500px] flex flex-col bg-gradient-to-tr from-ibb-accent to-ibb px-4 md:px-8 lg:px-16 py-16 md:py-32 text-white space-y-8 md:space-y-16">
          <h2 className="flex text-5xl md:text-6xl font-bold mx-auto">
            Leistungen im Überblick
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 gap-x-32 max-w-[1630px] mx-auto">
            <div className="flex flex-col justify-center items-start md:items-end">
              <ul className={`space-y-2  inline-flex flex-col ${list}`}>
                <li>Über 1.000 Stunden Schulungsmaterial</li>
                <li>42 Kurse in 30 Themengebieten</li>
                <li>Schulungsunterlagen zu jedem Kurs</li>
                <li>Chat-Support mit unseren Experten</li>
                <li>Tempor invidunt ut labore et dolore</li>
                <li>Stet clita kasd gubergren, no sea takimata</li>
              </ul>
            </div>
            <div className="flex items-center">
              <div
                className="inline-flex flex-col space-y-8 p-8 bg-white rounded-md bg-white text-slate-700 w-full md:w-[75%]">
                <h3 className="text-xl text-ibb font-bold">
                  Über 500 Kunden vertrauen ibb e-learning. Wann bist Du dabei?
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore magna aliquyam erat, sed diam voluptua.
                </p>
                <div className="flex flex-col md:flex-row items-center md:space-x-4 space-y-4 md:space-y-0">
                  <Link to="/registrieren">
                    <Button type="primary">
                      Jetzt registrieren
                    </Button>
                  </Link>
                  <Link to="/kontakt">
                    <Button type="secondary">
                      Kontakt aufnehmen
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
